import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import '../assets/sass/main.scss';
import Footer from './Footer';
import SideBar from './Sidebar';
import '@fontsource/allura';
class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPreloaded: true,
    };
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ isPreloaded: false });
    }, 100);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  render() {
    const { children, fullMenu, location, title: pageTitle } = this.props;
    const { isPreloaded } = this.state;

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={(data) => (
          <>
            <Helmet
              title={`${pageTitle ? `${pageTitle} | ` : ''}${
                data.site.siteMetadata.title
              }`}
              meta={[
                {
                  name: 'description',
                  content: 'May 27th, 2023 | Austin, TX',
                },
                { name: 'keywords', content: 'site, web' },
                { property: 'og:type', content: 'website' },
                {
                  property: 'og:url',
                  content: `https://linhandjames.com${location.pathname}`,
                },
                {
                  property: 'og:title',
                  content: `${pageTitle ? `${pageTitle} | ` : ''}${
                    data.site.siteMetadata.title
                  }`,
                },
                {
                  property: 'og:description',
                  content: 'May 27, 2023 | Austin, TX',
                },
                {
                  property: 'og:image',
                  content: 'https://linhandjames.com/meta.jpg',
                },
                { property: 'twitter:card', content: 'summary_large_image' },
                {
                  property: 'twitter:url',
                  content: `https://linhandjames.com${location.pathname}`,
                },
                {
                  property: 'twitter:title',
                  content: `${pageTitle ? `${pageTitle} | ` : ''}${
                    data.site.siteMetadata.title
                  }`,
                },
                {
                  property: 'twitter:description',
                  content: 'May 27th, 2023 | Austin, TX',
                },
                {
                  property: 'twitter:image',
                  content: 'https://linhandjames.com/meta.jpg',
                },
              ]}
            >
              <html lang="en" />
            </Helmet>
            <div
              className={
                isPreloaded
                  ? 'landing main-body is-preload'
                  : 'landing main-body'
              }
            >
              <div id="page-wrapper">
                <SideBar fullMenu={fullMenu} />
                {children}
                <Footer />
              </div>
            </div>
          </>
        )}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
